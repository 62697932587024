import React from 'react'
import { Form, Input, Button } from 'antd'
import { authenticateUser, dummy } from '../../utils/actions'
import { saveDeviceData } from '../../utils/helpers'
import { LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
}

class StyledUserLogin extends React.Component {
  formRef = React.createRef()

  state = {
    loading: false
  }

  handleSubmit = (values) => {
    const { setAuth, setUserRoles, setActiveModules, redirect, redirectRoute, requiredSubs } = this.props

    ReactGA.event({ category: 'User', action: 'Login' })

    this.setState({ loading: true })

    authenticateUser(values.login, values.password).then((json) => {
      this.setState({ loading: false })
      saveDeviceData('auth', json.token)
      saveDeviceData('refresh_token', json.refresh_token, 30)
      setAuth(true)

      dummy().then((result) => {
        setUserRoles(result.roles)
        setActiveModules(result.active_modules)

        // if (redirectRoute && redirectRoute.length && redirectRoute[0] !== '/') {
        //   if (redirectRoute[0] === '/pakiety' && redirectRoute.length > 1 && requiredSubs.includes(result.sub_type)) {
        //     redirect(redirectRoute[1], redirectRoute.slice(2))
        //   } else {
        //     redirect(redirectRoute[0], redirectRoute.slice(1))
        //   }
        // } else {
          redirect('/book')
        // }
      })
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  render () {
    return (
      <div className='login-form'>
        <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
          <Form.Item
            name='login'
            label={this.props.t('Email')}
            rules={[{ required: true, message: this.props.t('Email field is required') }]}
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='password'
            label={this.props.t('Password')}
            rules={[{ required: true, message: this.props.t('Password field is required') }]}
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className='form-footer'>
            <Button htmlType='submit' disabled={this.state.loading}>{this.props.t('Login')}{this.state.loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}</Button>
          </Form.Item>
          <p><Link to='/register'>{this.props.t('Don\'t have account? Register here')}</Link></p>
          <p><Link to='/reset-password'>Zapomniałem/am hasło</Link></p>
        </Form>
      </div>
    )
  }
}

export default withTranslation()(StyledUserLogin)
