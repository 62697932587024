import React from 'react'
import { notification, Button } from 'antd'
import moment from 'moment'

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
}

export function firstLetterUpperCase (text) {
  return text.charAt(0).toUpperCase() + text.substr(1);
}

export function setCookie (cname, cvalue, expireInDays) {
  var d = new Date()
  d.setTime(d.getTime() + expireInDays * 86400000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function deleteCookie (cname) {
  setCookie(cname, '', '')
}

export function getDeviceData (key) {
  return window.localStorage.getItem(key)
}

export function saveDeviceData (key, value) {
  window.localStorage.setItem(key, value)
}

export function removeDeviceData (key) {
  window.localStorage.removeItem(key)
}

export function openNotification (title, message, duration) {
  return notification.open({
    message: title,
    description: message,
    duration: duration || 2.5
  })
}

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/

export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div'
  tag = item.href ? 'div' : tag
  let children = typeof item.children === 'string' && item.children.match(isImg)
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    })
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children)
}

export const niclyFormatDate = (year, month, day) => {
  const monthName = moment(year + '-' + month + '-' + day, 'YYYY-M-D').format('MMMM')
  const currentYear = moment().format('YYYY')

  if (year === currentYear) {
    if (day) {
      return day + ' ' + monthName
    } else {
      return monthName
    }
  } else {
    if (day) {
      return day + ' ' + monthName + ' ' + year
    } else {
      return monthName + ' ' + year
    }
  }
}

export const niclyFormatPortions = (portions) => {
  const p = String(portions)

  if (p === '1') {
    return '1 porcja'
  } else if (['12', '13', '14'].includes(p)) {
    return p + ' porcji'
  } else if (['2', '3', '4'].includes(p[p.length - 1])) {
    return p + ' porcje'
  } else {
    return p + ' porcji'
  }
}

export const getOrderStatusColor = (status) => {
  if (status === 'finished') {
    return 'orange'
  } else if (status === 'canceled') {
    return 'gray'
  } else {
    return 'green'
  }
}

export const getCertificateStatusColor = (status) => {
  if (status === 'finished') {
    return 'gray'
  } else if (status === 'rejected') {
    return 'red'
  } else {
    return 'green'
  }
}

export const getPaymentStatusColor = (status) => {
  if (status === 'ok') {
    return 'green'
  } else {
    return 'red'
  }
}

export function slugify (str) {
  return str
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
}

export function trainingLevel (level) {
  const levels = {
    1: 'Początkujący',
    2: 'Średnio zaawansowany',
    3: 'Zaawansowany',
    4: 'Ekspert'
  }
  return levels[level] || ''
}

export function subName (name) {
  const names = {
    'free': 'darmowe',
    'sub1': 'Muffinka',
    'sub2': 'Monoporcja',
    'sub3': 'Beza'
  }
  return names[name] || ''
}

export function getUrlParameter (param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param)
}

export function getPaymentErrorCodeInfo (name) {
  const errors = {
    'card_declined': 'Karta odrzucona',
    'expired_card': 'Karta wygasła',
    'incorrect_cvc': 'Błędny kod CVC',
    'processing_error': 'Błąd procesowania',
    'incorrect_number': 'Błędny numer'
  }
  return errors[name] || ''
}

export function getPaymentDecelineCodeInfo (name) {
  const errors = {
    'generic_decline': 'Ogólny błąd',
    'insufficient_funds': 'Zbyt mało środków na karcie',
    'lost_card': 'Karta zgubiona',
    'stolen_card': 'Karta skradziona',
    'card_velocity_exceeded': 'Przekroczono limit na karcie'
  }
  return errors[name] || ''
}

export function nl2br (text) {
  const splited = text.split('\n')
  const results = []

  for (let line of splited) {
    results.push(<>{line}<br /></>)
  }

  return results
}

// export const quantityUnits = {
//   portion_1: 'porcja',
//   portion_2: 'porcje',
//   portion_3: 'porcji',
//   weight: 'g',
// }

export const quantityRimLayers = {
  1: 'jedno przełożenie',
  2: 'dwa przełożenia',
  3: 'trzy przełożenia',
  4: 'cztery przełożenia',
  5: 'pięć przełożeń',
}
