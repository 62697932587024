import { combineReducers } from 'redux'

const appRedux = (state = { userRoles: [], activeModules: [] }, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        authenticated: action.authenticated
      }
    case 'SET_INSTALL_PROMPT_SHOWED':
      return {
        ...state,
        installPromptShowed: action.installPromptShowed
      }
    case 'SET_USER_ROLES':
      return {
        ...state,
        userRoles: action.userRoles
      }
    case 'SET_ACTIVE_MODULES':
      return {
        ...state,
        activeModules: action.activeModules
      }
    case 'SET_CONFIRM_MODAL_VISIBLE':
      return {
        ...state,
        confirmModalVisible: action.confirmModalVisible
      }
    case 'SET_CTA_MODAL_VISIBLE':
      return {
        ...state,
        ctaModalVisible: action.ctaModalVisible
      }
    case 'SET_GLOBAL_MESSAGE':
      return {
        ...state,
        globalMessage: action.globalMessage
      }
    default:
      return state
  }
}

const reducers = combineReducers({
  appRedux
})

export default reducers
