import React, { Component } from 'react'
import StyledMenuMain from './menuMain'
import { Drawer, Row, Col, Alert } from 'antd'
import { menuTypeHook } from '../../components/common/menuTypeHook'
// import ConfirmModal from '../../components/userPurchase/confirmModal'
// import TweenOne from 'rc-tween-one'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

class StyledHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mobileVisible: false,
      isMobile: undefined
    }
  }

  handleShowMobileMenu = () => {
    this.setState({
      mobileVisible: !this.state.mobileVisible
    })
  };

  handleHideMobileMenu = () => {
    this.setState({
      mobileVisible: false
    })
  };

  componentDidMount () {
    this.setState({ isMobile: this.props.menuType === 'inline' })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.menuType !== this.props.menuType) {
      this.setState({ isMobile: this.props.menuType === 'inline' })
    }
  }

  render () {
    const { mobileVisible, isMobile/*, phoneOpen*/ } = this.state
    const { logout, isAuthenticated, activeModules, userRoles, globalMessage } = this.props

    // const moment = phoneOpen === undefined ? 300 : null

    return (
      <>
        {/*<ConfirmModal />*/}
        {globalMessage &&
          <div className='global-message' style={{ '--text-color': globalMessage?.text_color, '--bg-color': globalMessage?.bg_color, '--link-color': globalMessage?.link_color }}>
            <ReactMarkdown>{globalMessage?.message}</ReactMarkdown>
          </div>}
        <div className='header-main-container'>
          {isMobile ? (
            <span className={'hamburger' + (mobileVisible ? ' open' : '')} onClick={this.handleShowMobileMenu}>
              <em />
              <em />
              <em />
            </span>) : ''}
          <div className={'header-container' + (isMobile ? ' header-container-mobile' : '')}>
            {isMobile &&
              <div className='logo-mobile'><Link to='/'><img src='/images/logo.svg' alt='Logo' /></Link>
            </div>}
            {!isMobile && <Row className='main-menu'>
              <Col xs={18} md={{ span: 7 }} lg={{ span: 7 }}>
                <Link to='/'><img className='logo' src='/images/logo.svg' alt='Logo' /></Link>
              </Col>
              <Col xs={6} md={{ span: 17 }} lg={{ span: 17 }}>
                <StyledMenuMain isAuthenticated={isAuthenticated} handleLogout={logout} activeModules={activeModules} userRoles={userRoles} />
              </Col>
            </Row>}
          </div>

          <Drawer
            title='xBook'
            placement='right'
            onClose={this.handleShowMobileMenu}
            className='menu-drawer'
            visible={mobileVisible}
            width={350}
          >
            <StyledMenuMain isAuthenticated={isAuthenticated} isMobile onHideMobileMenu={this.handleHideMobileMenu} handleLogout={logout} activeModules={activeModules} userRoles={userRoles} />
          </Drawer>
        </div>
        {/*<TweenOne
          component='header'
          animation={{ opacity: 0, type: 'from' }}
          id='Nav0_0'
          key='Nav0_0'
          className='header0 home-page-wrapper'
        >
          <div
            className={`home-page${phoneOpen ? ' open' : ''}`}
          >
            <TweenOne
              animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
              className='header0-logo'
            >
              <Link to='/'>
                <img width='100%' src='/images/logo.png' alt='Logo' />
              </Link>
            </TweenOne>

            {(!isMobile && (
              <TweenOne
                className='header0-menu'
                animation={
                  isMobile
                    ? {
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = 'auto'
                        }
                      },
                      ease: 'easeInOutQuad'
                    }
                    : null
                }
                moment={moment}
                reverse={!!phoneOpen}
              >
                <StyledMenuMain isAuthenticated={isAuthenticated} handleLogout={logout} activeModules={activeModules} />
              </TweenOne>
            ))}
            <Drawer
              title='Strefa Cukiernika'
              placement='right'
              onClose={this.handleShowMobileMenu}
              className='menu-drawer'
              visible={mobileVisible}
              width={350}
            >
              <StyledMenuMain isAuthenticated={isAuthenticated} isMobile onHideMobileMenu={this.handleHideMobileMenu} handleLogout={logout} activeModules={activeModules} />
            </Drawer>
          </div>

        </TweenOne>*/}

      </>
    )
  }
}

export default menuTypeHook(StyledHeader)
