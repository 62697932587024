/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Row, Col, InputNumber, Card, Popconfirm, Button, Divider, DatePicker, Select, Checkbox, Rate, Space, Upload, Descriptions
} from 'antd'
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined,
  PaperClipOutlined, CameraOutlined, ReloadOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons'
import Icon from '@ant-design/icons'
import settings from '../../config'

import { ReactComponent as RateMain } from '../../assets/images/rate_main.svg';
import { ReactComponent as RateHot } from '../../assets/images/rate_hot.svg';
import { ReactComponent as RateFear } from '../../assets/images/rate_fear.svg';
import { ReactComponent as RateRiddle } from '../../assets/images/rate_riddle.svg';
import { getDeviceData } from '../../utils/helpers'

import moment from 'moment'
const { Option } = Select

const formItemLayout = {
  // labelCol: {
  //   xs: 6,
  //   // sm: 12,
  //   md: 6,
  //   lg: 6
  // },
  // wrapperCol: {
  //   xs: 24,
  //   // sm: 10,
  //   md: 24,
  //   lg: 24
  // }
}

const formItemLayout2 = {
  labelCol: {
    xs: 24,
    // sm: 12,
    md: 24,
    lg: 24
  },
  wrapperCol: {
    xs: 24,
    // sm: 10,
    md: 24,
    lg: 24
  }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

const genres = {
  'fiction': 'Fikcja',
  'dupcion': 'Dupcja'
}

const minAges = {
  0: 'bez ograniczeń',
  13: '13+',
  16: '16+',
  18: '18+'
}

const { TextArea } = Input

class BookForm extends React.Component {
  state = {
  }

  formRef = React.createRef()
  pictureRef = React.createRef()
  imageRef = React.createRef()

  saveFormData = () => {
    if (this.state.timer) return

    const _this = this
    const timer = setTimeout(() => {
      const values = this.formRef.current?.getFieldsValue()

      _this.props.formAction(values)
      _this.setState({ timer: null })
    }, 2000)

    this.setState({ timer })
  }

  componentDidMount () {
    const { formData } = this.props

    if (formData) {
      this.setFormData(formData)
    }
  }

  setFormData = (formData) => {
    const dateFormat = 'YYYY-MM-DD';

    if (formData.premiere_date) {
      formData.premiere_date = moment(formData.premiere_date, dateFormat, 'Europe/Warsaw')
    }
    if (formData.read_start) {
      formData.read_start = moment(formData.read_start, dateFormat, 'Europe/Warsaw')
    }
    if (formData.read_finish) {
      formData.read_finish = moment(formData.read_finish, dateFormat, 'Europe/Warsaw')
    }

    this.formRef.current.setFieldsValue(formData)

    this.setState({
      fData: formData
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if (formData && prevProps.formData !== formData) {
      this.setFormData(formData)
    }
  }

  setJustUploaded = (justUploaded) => {
    this.setState({ justUploaded })

    this.imageRef.current.src = '/dimages/book_images/min-' + this.props.formData?.id + '.jpg?' + new Date().getTime();
  }

  setEdit = (editing) => {
    const fData = this.formRef.current.getFieldsValue()
    this.setState({ editing, fData })
    this.props.setTitle(fData.title)
    this.saveFormData()
  }

  cameraNumber = 0

  initializeMedia = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((localMediaStream) => {
        this.fireMedia()
      })
      .catch((error) => {
        console.log('Rejected!', error)
      })
    // navigator.permissions.query({ name: 'camera' })
    // .then((permissionObj) => {
    //   if (permissionObj.state !== 'granted') {
    //     navigator.mediaDevices
    //       .getUserMedia({ video: true })
    //       .then((localMediaStream) => {
    //         this.fireMedia()
    //       })
    //       .catch((error) => {
    //         console.log('Rejected!', error)
    //       })
    //   } else {
    //     this.fireMedia()
    //   }
    // })
    // .catch((error) => {
    //   console.log('Got error :', error)
    // })
  }

  fireMedia = async () => {
    this.setState({ imageDataURL: null, capturing: true });
    this.player.style.display = 'block'

    if (!('mediaDevices' in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!('getUserMedia' in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia Not Implemented'))
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject)
        });
      };
    }

    const videoInputs = await this.getListOfVideoInputs()

    if (videoInputs.length) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: {
              exact: videoInputs[this.cameraNumber].deviceId,
            },
          }
        })
        .then((stream) => {
          this.player.srcObject = stream;
        })
        .catch((error) => {
          console.error(error);

          if (videoInputs.length > 1) {
            this.switchCamera()
          }
        })
    } else {
      alert("Urządzenie nie ma dostępnych aparatów")
    }
  }

  capturePicture = () => {
    const player = this.player
    this.setState({ processingImage: true })
    player.style.display = 'none'

    const { formData: fData } = this.props
    console.log(fData)
    var canvas = this.pictureRef.current
    canvas.width = this.player.videoWidth
    canvas.height = this.player.videoHeight
    var contex = canvas.getContext('2d')
    contex.drawImage(this.player, 0, 0, canvas.width, canvas.height)

    this.player.srcObject?.getVideoTracks().forEach((track) => {
      track.stop()
    })

    const _this = this

    canvas.toBlob(function(blob) {
      const formData = new FormData();
      formData.append('file', blob, 'filename.png')

      const headers = {}
      headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

      fetch(settings.api_url + `/api/book/${fData.id}/upload`, {
        method: 'POST',
        body: formData,
        headers
      })
        .then((response) => response.json())
        .then((data) => {
          _this.setJustUploaded(data?.filename)
          _this.setState({ capturing: false, processingImage: false });
        })
        .catch((error) => {
          console.error('Error uploading image:', error)
        })
    })
  }

  cancelCapturing = () => {
    this.setState({ capturing: false })
    this.player.style.display = 'none'
    this.player.srcObject?.getVideoTracks().forEach((track) => {
      track.stop()
    })
  }

  switchCamera = async () => {
    const listOfVideoInputs = await this.getListOfVideoInputs();

    if (listOfVideoInputs.length > 1) {
      if (this.player.srcObject) {
        this.player.srcObject?.getVideoTracks().forEach((track) => {
          track.stop()
        })
      }

      if (this.cameraNumber + 1 === listOfVideoInputs.length) {
        this.cameraNumber = 0;

      } else {
        this.cameraNumber = this.cameraNumber + 1
      }

      this.initializeMedia()
    } else if (listOfVideoInputs.length === 1) {
      alert('Urządzenie ma tylko jeden aparat')
    } else {
      alert('Urządzenie nie ma żadnych dostępnych aparatów')
    }
  }

  getListOfVideoInputs = async () => {
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices()
    return enumerateDevices.filter((device) => device.kind === 'videoinput')
  }

  listField = (fieldName, fieldTitle, addButtonTitle, cssClass) => {
    const { isMobile } = this.props
    const { editing, fData } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    return (<Form.List
      name={fieldName}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <Card
            title={fieldTitle + ':'}
            className={'book-box ' + cssClass}
            extra={editing === fieldName ? [
              <Button
                type='secondary'
                onClick={() => add('')}
                icon={<PlusOutlined />}
                />, <Button className='book-edit' icon={<CheckOutlined />} onClick={() => this.setEdit(null)} />] :<Button className='book-edit' icon={<EditOutlined />} onClick={() => this.setEdit(fieldName)} />}
          >
            {editing !== fieldName && <ul>{fData && fData[fieldName] && fData[fieldName].filter(x => x).map(x => <li>{x}</li>)}</ul>}
            {editing === fieldName && fields.map(({ key, name, ...restField }) => (
              <React.Fragment key={key}>
                <Form.Item
                  {...restField}
                  name={[name, 'id']}
                  hidden
                >
                  <Input />
                </Form.Item>

                <Row gutter={[3, 0]}>
                  <Col xs={21}>
                    <Form.Item
                      {...restField}
                      name={name}
                      validateTrigger={['onChange', 'onBlur']}
                      style={{ marginBottom: '10px' }}
                    >
                      <Input autoComplete='off' />
                    </Form.Item>
                  </Col>

                  <Col xs={3} className={!isMobile ? 'form-item-actions' : ''}>
                    <Popconfirm
                      title='Czy na pewno chcesz usunąć ten element?'
                      onConfirm={() => { remove(name); this.saveFormData(); }}
                      {...popconfirmItemDeleteProps}
                    >
                      <MinusCircleOutlined
                        className='dynamic-delete-button'
                        title='Usuń'
                      />
                    </Popconfirm>
                  </Col>
                </Row>
                {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
              </React.Fragment>
            ))}

            <Form.ErrorList errors={errors} />
          </Card>
        </>
      )}
    </Form.List>)
  }

  render () {
    const { isMobile, formData } = this.props
    const { capturing, processingImage, editing, fData } = this.state

    const selectOptions = [
      { label: <img src='/images/book.png' className='book-type-icon' alt='Book' />, value: 'book', key: 'book' },
      { label: <img src='/images/ebook.png' className='book-type-icon' alt='eBook' />, value: 'ebook', key: 'ebook' },
      { label: <img src='/images/audiobook.png' className='book-type-icon' alt='Audio book' />, value: 'audiobook', key: 'audiobook' },
    ]
    const headers = {}
    headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          layout='horizontal'
          ref={this.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
          onValuesChange={this.saveFormData}
          style={{ maxWidth: '1000px', margin: '0 auto 20px auto', textAlign: 'left' }}
        >
          <Row style={{ margin: '0 20px' }}>
            <Col xs={24} sm={8}>
              <Space direction='vertical'>
                <img ref={this.imageRef} width={196} src={'/dimages/book_images/min-' + this.props.formData?.id + '.jpg?' + new Date().getTime()} alt='Book cover' />
                <canvas
                  ref={this.pictureRef}
                  style={{ display: 'none' }}
                />
                <Space>
                  {!capturing && <Upload
                    action={settings.api_url + `/api/book/${formData?.id}/upload`}
                    headers={headers}
                    accept='image/png,image/jpeg'
                    showUploadList={null}
                    beforeUpload={() => {
                      this.setState({ processingImage: true })

                      return true
                    }}
                    onChange={(x) => {
                      if(x.file.status === 'done') {
                        this.setJustUploaded(x?.file?.response?.filename)
                        this.setState({ processingImage: false })
                      }
                    }}
                  >
                    <Button icon={<PaperClipOutlined />} style={{ marginRight: '0' }} />
                  </Upload>}

                  {processingImage && <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin />}

                  <video
                    ref={(refrence) => {
                      this.player = refrence;
                    }}
                    style={{
                      width: '200px',
                      display: 'none'
                    }}
                    autoPlay
                  ></video>

                  <Space direction='vertical'>
                    {capturing && !processingImage && <Button type='primary' onClick={this.capturePicture} title='Zrób zdjęcie' icon={<CameraOutlined />} />}
                    {capturing && !processingImage && <Button onClick={this.switchCamera} icon={<ReloadOutlined />} title='Zmień aparat' />}
                    {capturing && !processingImage && <Button onClick={this.cancelCapturing}>Anuluj</Button>}
                  </Space>

                  {!capturing && <Button
                    onClick={this.initializeMedia}
                    icon={<CameraOutlined />} style={{ marginRight: '15px' }} />}
                </Space>
              </Space>

              <Form.Item
                name='rate_main'
                {...formItemLayout}
              >
                <Rate character={<Icon component={RateMain} />} />
              </Form.Item>
              <Form.Item
                name='rate_hot'
                {...formItemLayout}
              >
                <Rate character={<Icon component={RateHot} />} />
              </Form.Item>
              <Form.Item
                name='rate_riddle'
                {...formItemLayout}
              >
                <Rate character={<Icon component={RateRiddle} />} />
              </Form.Item>
              <Form.Item
                name='rate_fear'
                {...formItemLayout}
              >
                <Rate character={<Icon component={RateFear} />} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16}>
              <Card
                className='book-box book-box-1'
              >
                {editing === 'title' ? <Button className='book-edit' icon={<CheckOutlined />} onClick={() => this.setEdit(null)} /> : <Button className='book-edit' icon={<EditOutlined />} onClick={() => this.setEdit('title')} />}
                {editing !== 'title' && <p className='book-field-title'><span>Tytuł:</span> {fData?.title}</p>}
                <Form.Item
                  name='title'
                  label='Tytuł'
                  rules={[{ required: true, message: 'To pole jest wymagane' }]}
                  hidden={editing !== 'title'}
                  {...formItemLayout}
                >
                  <Input />
                </Form.Item>
              </Card>
              <Card
                className='book-box book-box-1'
              >
                {editing === 'author' ? <Button className='book-edit' icon={<CheckOutlined />} onClick={() => this.setEdit(null)} /> : <Button className='book-edit' icon={<EditOutlined />} onClick={() => this.setEdit('author')} />}
                {editing !== 'author' && <p className='book-field-title'><span>Autor:</span> {fData?.author || '-'}</p>}
                <Form.Item
                  name='author'
                  label='Autor'
                  hidden={editing !== 'author'}
                  {...formItemLayout}
                >
                  <Input />
                </Form.Item>
              </Card>

              <Card
                className='book-box book-box-2'
                style={{ paddingTop: '20px' }}
              >
                {editing === 'details' ? <Button className='book-edit' icon={<CheckOutlined />} onClick={() => this.setEdit(null)} /> : <Button className='book-edit' icon={<EditOutlined />} onClick={() => this.setEdit('details')} />}
                {editing !== 'details' && (
                  <Descriptions size='small' column={isMobile ? 1 : 2} className='book-details' colon={false}>
                    <Descriptions.Item label="Gatunek:">{(fData?.genre && genres[fData.genre]) || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Wydawnictwo:">{fData?.publishing_house || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Data premiery:">{fData?.premiere_date?.format('YYYY-MM-DD') || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Seria:">{fData?.series || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Ocena wiekowa:">{(fData?.min_age && genres[fData.min_age]) || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Do polecenia:">{(fData?.recommendation && 'TAK') || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Liczba stron:" span={3}>{fData?.pages_count || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Start:">{fData?.read_start?.format('YYYY-MM-DD') || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Koniec:">{fData?.read_finish?.format('YYYY-MM-DD') || '-'}</Descriptions.Item>
                    <Descriptions.Item label="Nośnik:">{fData?.type?.map(y => selectOptions.find(x => x.key === y).label)}</Descriptions.Item>
                  </Descriptions>
                )}

                <Form.Item
                  name='publishing_house'
                  label='Wydawnictwo'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name='genre'
                  label='Gatunek'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <Select allowClear>
                    {
                      Object.keys(genres).map((ing, n) =>
                        <Option key={n} value={ing}>{genres[ing]}</Option>
                      )
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  name='premiere_date'
                  label='Data premiery'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <DatePicker />
                </Form.Item>

                <Form.Item
                  name='pages_count'
                  label='Liczba stron'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <InputNumber min={1} />
                </Form.Item>

                <Form.Item
                  name='min_age'
                  label='Ocena wiekowa'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <Select allowClear>
                    {
                      Object.keys(minAges).map(val =>
                        <Option key={val} value={parseInt(val)}>{minAges[val]}</Option>
                      )
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  name='type'
                  label='Nośnik'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                  className='book-type-select'
                >
                  <Checkbox.Group
                    options={selectOptions}
                  />
                </Form.Item>

                <Form.Item
                  name='series'
                  label='Seria'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name='recommendation'
                  label='Do polecenia'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                  valuePropName='checked'
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name='read_start'
                  label='Start'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <DatePicker />
                </Form.Item>

                <Form.Item
                  name='read_finish'
                  label='Koniec'
                  hidden={editing !== 'details'}
                  {...formItemLayout}
                >
                  <DatePicker />
                </Form.Item>
              </Card>

              {this.listField('main_characters', 'Główni bohaterowie', 'Dodaj głównego bohatera', 'book-box-2')}
            </Col>
          </Row>

          <Row style={{ margin: '0 10px' }} gutter={[20, 0]}>
            <Col xs={24} sm={12}>
              {this.listField('quotes', 'Cytaty', 'Dodaj cytat', 'book-box-3')}
            </Col>
            <Col xs={24} sm={12}>
              <Card
                title='Streszczenie:'
                className='book-box book-box-3 book-box-single'
                extra={editing === 'summary' ? <Button className='book-edit' icon={<CheckOutlined />} onClick={() => this.setEdit(null)} /> : <Button className='book-edit' icon={<EditOutlined />} onClick={() => this.setEdit('summary')} />}
              >
                {editing !== 'summary' && fData?.summary}
                <Form.Item
                  name='summary'
                  hidden={editing !== 'summary'}
                  // label='Streszczenie'
                  {...formItemLayout2}
                >
                  <TextArea style={{ height: '200px' }}/>
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Row style={{ margin: '0 10px' }} gutter={[20, 0]}>
            <Col xs={24} sm={12}>
              {this.listField('playlist', 'Playlista', 'Dodaj utwór', 'book-box-4')}
            </Col>
            <Col xs={24} sm={12}>
              <Card
                title='Recenzja:'
                className='book-box book-box-4 book-box-single'
                extra={editing === 'review' ? <Button className='book-edit' icon={<CheckOutlined />} onClick={() => this.setEdit(null)} /> : <Button className='book-edit' icon={<EditOutlined />} onClick={() => this.setEdit('review')} />}
              >
                {editing !== 'review' && fData?.review}
                <Form.Item
                  name='review'
                  hidden={editing !== 'review'}
                  {...formItemLayout2}
                >
                  <TextArea style={{ height: '200px' }}/>
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Row style={{ margin: '0 20px' }}>
            <Col xs={24} sm={12}>
              {this.listField('pros', 'Plusy', 'Dodaj plus', 'book-box-4' + (!isMobile ? ' book-box-left' : ''))}
            </Col>
            <Col xs={24} sm={12}>
              {this.listField('cons', 'Minusy', 'Dodaj minus', 'book-box-4' + (!isMobile ? ' book-box-right' : ''))}
            </Col>
          </Row>
        </Form>
      </>
    )
  }
}

export default BookForm
