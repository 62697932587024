import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import ScrollToTop from './components/common/scrollToTop'
import Main from './components/main'
import UserLogin from './components/user/login'
import Register from './components/user/register'
import UserProfile from './components/user/profile'
import BookIndex from './components/book'
import BookShow from './components/book/show'

import { setAuth, setInstallPromptShowed, setUserRoles, setActiveModules, setGlobalMessage } from './store/actions'
import { getCookie, getDeviceData } from './utils/helpers'
import withTracker from './utils/withTracker'
import { dummy } from './utils/actions'

class Routes extends React.Component {
  componentDidMount () {
    const { setAuth, setInstallPromptShowed } = this.props
    const token = getDeviceData('auth')
    const installPromptShowed = getCookie('install_prompt_showed')

    if (token) {
      setAuth(true)
    }

    dummy().then((result) => {
      this.props.setUserRoles(result.roles)
      this.props.setActiveModules(result.active_modules)
      this.props.setGlobalMessage(result.global_message)
    })

    if (installPromptShowed) {
      setInstallPromptShowed(true)
    }
  }

  render () {
    const UserProfileWithTracker = withTracker(UserProfile)
    const RegisterWithTracker = withTracker(Register)
    const BookShowWithTracker = withTracker(BookShow)
    const BookIndexWithTracker = withTracker(BookIndex)

    return (
      <>
        <ScrollToTop />
            <Switch>
              <Route exact path='/book' render={(props) => <BookIndexWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/book/:id' render={(props) => <BookShowWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/register' render={(props) => <RegisterWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/login' component={withTracker(UserLogin)} />
              <Route exact path='/profile' render={(props) => <UserProfileWithTracker {...props} isMobile={this.props.isMobile} />} />

              {/*<Route exact path='/reset-password' component={withTracker(UserResetPassword)} />
              <Route exact path='/reset-password/:token' component={withTracker(UserResetPasswordChange)} />*/}

              {window.matchMedia('(display-mode: standalone)').matches ? (
                <Route exact path='/' component={withTracker(UserLogin)} />
              ) : (
                <Route component={withTracker(Main)} />
              )}
            </Switch>
      </>
    )
  }
}

const mapStateToProps = state => ({
  // authenticated: state.appRedux.authenticated,
  // userRoles: state.appRedux.userRoles
})

const mapDispatchToProps = {
  setAuth,
  setInstallPromptShowed,
  setUserRoles,
  setActiveModules,
  setGlobalMessage
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)

export default RoutesContainer
