/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'
import { connect } from 'react-redux'
import { Button, Space } from 'antd'
// import { Link as LinkScroll } from 'react-scroll'
// import { Alert } from 'antd'
import { Link } from 'react-router-dom'
// import { getTrainings, getStats, getTrainingAuthors, getPartners } from '../../utils/actions'

// import ContentItems from '../common/contentItems'
// import ContentSlider from '../common/contentSlider'
import ContentLeft from '../common/contentLeft'
// import Content from '../common/content'

const { location = {} } = typeof window !== 'undefined' ? window : {}

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: undefined,
      show: !location.port
    }
  }

  redirect = (pathname, state) => {
    this.props.history.push({ pathname, state })
  }

  componentDidMount () {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }
  }

  render () {
    const { isMobile } = this.state

    const children = [
      // <IndexBanner
      //   id='Banner0_0'
      //   key='Banner0_0'
      //   dataSource={IndexBannerDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <ContentLeft
        isMobile={isMobile}
        // image='/images/cake.png'
        title='Moja biblioteczka'
        className='welcome'
        content={
          <>
            <Space wrap>
              <Button className='sc-button'><Link to='/register'>Zarejestruj się za darmo</Link></Button>
              <Button className='sc-button'><Link to='/register'>Zaloguj się</Link></Button>
            </Space>
          </>
        }
      />,
      // <Content
      //   id='Content13_0'
      //   isMobile={isMobile}
      //   title='Czym jest Strefa Cukiernika?'
      //   className='explain'
      //   content={
      //     <Space direction='vertical' size='large'>
      //       <p>Strefa Cukiernika to miejsce (oraz społeczność ;) dedykowana osobom zajmującymi się wypiekami.</p>
      //       <p>Znajdziesz tutaj przydatne narzędzia, wiedzę i dodatki, które ułatwią Ci codzienną pracę w słodkim świecie!</p>
      //       <Button className='sc-button register'><Link to='/register'>Dołącz do Strefy Cukiernika</Link></Button>
      //     </Space>
      //   }
      // />,
      // <ContentItems
      //   getData={() =>
      //     new Promise(function (resolve, reject) {
      //       getTrainings().then((json) => {
      //         resolve(json.trainings)
      //       }).catch((error) => reject(error))
      //     })
      //   }
      //   renderItem={(item) => {
      //     return <TrainingItem item={item} redirect={this.redirect} />
      //   }}
      //   title={
      //     <>
      //       <span>Nasze szkolenia</span>
      //       <TrainingSearchForm formAction={(data) => this.redirect('/training', { trainingSearch: data?.search })} />
      //     </>}
      //   isMobile={this.state.isMobile}
      //   more={{
      //     title: 'POKAŻ WIĘCEJ',
      //     route: '/training'
      //   }}
      // />,
      // <ContentItems
      //   items={[
      //     {
      //       route: '/calculator',
      //       title: 'Kalkulator wycen',
      //       img: 'calculator.png?v2',
      //     },
      //     {
      //       route: '/calculator-portions',
      //       title: 'Kalkulator porcji',
      //       img: 'calculator-portions.png?v2',
      //     },
      //     {
      //       route: '/order',
      //       title: 'Obsługa zamówień',
      //       img: 'orders.png?v2',
      //     },
      //     {
      //       route: '/myrecipes',
      //       title: 'Moje przepisy',
      //       img: 'recipes.png?v2',
      //     }
      //   ]}
      //   itemXs={24}
      //   itemSm={12}
      //   itemMd={12}
      //   itemLg={6}
      //   renderItem={(item) => {
      //     return <div class='item'><Link to={item.route}><img src={'/images/' + item.img} alt='item' /></Link><p><Link className='text-link' to={item.route}><span>{item.title}</span><span className='line'></span></Link></p></div>
      //   }}
      //   className='tools'
      //   title='Przydatne narzędzia'
      //   isMobile={this.state.isMobile}
      // />,
      // <ContentItems
      //   getData={() =>
      //     new Promise(function (resolve, reject) {
      //       // setTimeout(resolve, 3000)
      //       getStats().then((json) => {
      //         resolve(json.stats)
      //       }).catch((error) => reject(error))
      //     })
      //   }
      //   loadingItems={[{ text: '-', data: '-' }, { text: '-', data: '-' }, { text: '-', data: '-' }]}
      //   className='stats'
      //   itemXs={24}
      //   itemSm={24}
      //   itemMd={6}
      //   itemLg={6}
      //   renderItem={(item) => {
      //     return <div><p className='stats-item-data'>{item.data}</p><p className='stats-item-desc'>{item.text}</p></div>
      //   }}
      //   isMobile={this.state.isMobile}
      // />,
      // <ContentItems
      //   getData={() =>
      //     new Promise(function (resolve, reject) {
      //       getTrainingAuthors().then((json) => {
      //         resolve(json.authors)
      //       }).catch((error) => reject(error))
      //     })
      //   }
      //   renderItem={(item) => {
      //     return <TrainingAuthorItem item={item} redirect={this.redirect} />
      //   }}
      //   title='Poznaj nasze Trenerki'
      //   isMobile={this.state.isMobile}
      //   more={{
      //     title: 'POKAŻ WIĘCEJ',
      //     route: '/training/author'
      //   }}
      // />,

      // <div key='tmp' style={{ marginTop: '100px' }} />
      // <IndexModules
      //   id='Content0_0'
      //   key='Content0_0'
      //   dataSource={IndexModulesDataSource}
      //   isMobile={this.state.isMobile}
      //   userRoles={[]}
      //   activeModules={[]}
      // />,
      // <IndexAccess
      //   id='Content1_0'
      //   key='Content1_0'
      //   dataSource={IndexAccessDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <ContentSlider
      //   getData={() =>
      //     new Promise(function (resolve, reject) {
      //       getPartners().then((json) => {
      //         resolve(json.partners)
      //       }).catch((error) => reject(error))
      //     })
      //   }
      //   renderItem={(item) => {
      //     return <div className='partners-item' style={{ margin: '0 auto' }}>
      //       <Link to={'/partnerzy/' + item.id}>
      //         <img src={'/images/partners/' + item.logo.replace('logo', 'logo-mini')} alt={item.name} className='slick-image' />
      //       </Link>
      //     </div>
      //   }}
      //   title='Partnerzy'
      //   className='partners'
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexPartners
      //   id='Content12_0'
      //   key='Content12_0'
      //   dataSource={PartnersDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexDevices
      //   id='Feature5_0'
      //   key='Feature5_0'
      //   dataSource={DevicesDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexExplainLeft
      //   id='Content6_0'
      //   key='Content6_0'
      //   dataSource={CalcDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <IndexExplainRight
      //   id='Content8_0'
      //   key='Content8_0'
      //   dataSource={OrdersDataSource}
      //   isMobile={this.state.isMobile}
      // />
    ]

    return (
      <div
        className='templates-wrapper'
        ref={(d) => {
          this.dom = d
        }}
      >
        {/*<Alert
          className='registration-alert'
          closable
          style={{ marginTop: '10px' }}
          message={
            <>
              {'Obecnie aplikacja jest w fazie testów, rejestracja jeszcze nie jest dostępna dla użytkowników. Zapraszamy wkrótce!'}
            </>
          }
          type='info'
        />*/}
        {this.state.show && children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const HomeContainer = connect(
  mapStateToProps
)(Home)

export default HomeContainer
