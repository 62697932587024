/* eslint no-unused-expressions: "off" */
import React from 'react'
import { Form, Input } from 'antd'

const formItemLayout = {
  labelCol: {
    xs: 10,
    // sm: 12,
    md: 10,
    lg: 10
  },
  wrapperCol: {
    xs: 24,
    // sm: 10,
    md: 24,
    lg: 24
  }
}

class AddBookForm extends React.Component {
  state = {
    rating: ''
  }

  handleSubmit = () => {
    this.props.setLoading(true)

    this.props.formAction({ ...this.props.formRef.current?.getFieldsValue() }).then((json) => {
      this.props.setLoading(false)
    }).catch(error => {
      this.props.setLoading(false)
    })
  }

  render () {
    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          layout='horizontal'
          ref={this.props.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='title'
            label='Tytuł'
            rules={[{ required: true, message: 'To pole jest wymagane' }]}
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default AddBookForm
