/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Modal, Button
} from 'antd'
import { withTranslation } from 'react-i18next'
import BookAddForm from '../../styledComponents/book/addForm'
import { addBook } from '../../utils/actions'
import { LoadingOutlined } from '@ant-design/icons'

class AddBookModal extends React.Component {
  state = {
    loading: false
  }

  formRef = React.createRef()

  addTrainingOpinion = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      addBook(data).then((json) => {
        _this.props.setModalVisibility(false)
        _this.props.redirect('/book/' + json.id)

        _this.props.setModalVisibility(false)
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  setLoading = (loading) => {
    this.setState({ loading })
  }

  render () {
    const { isMobile } = this.props

    return (
      <Modal
        title='Dodaj książkę'
        visible={this.props.visible}
        onCancel={() => this.props.setModalVisibility(false)}
        footer={[
          <Button
            type='primary'
            key='add-opinion'
            onClick={() => this.formRef.current.submit()}
            disabled={this.state.loading}
          >
            Dodaj książkę{this.state.loading && <> <LoadingOutlined style={{ fontSize: 14 }} spin /></>}
          </Button>,
          <Button key='not-now' onClick={() => { this.props.setModalVisibility(false) }}>Anuluj</Button>,
        ]}
      >
        <BookAddForm
          formAction={this.addTrainingOpinion}
          formRef={this.formRef}
          isMobile={isMobile}
          setLoading={this.setLoading}
        />
      </Modal>
    )
  }
}

export default withTranslation()(AddBookModal)
