export const setAuth = authenticated => ({
  type: 'SET_AUTH',
  authenticated
})

export const setInstallPromptShowed = installPromptShowed => ({
  type: 'SET_INSTALL_PROMPT_SHOWED',
  installPromptShowed
})

export const setUserRoles = userRoles => ({
  type: 'SET_USER_ROLES',
  userRoles
})

export const setActiveModules = activeModules => ({
  type: 'SET_ACTIVE_MODULES',
  activeModules
})

export const setConfirmModalVisible = confirmModalVisible => ({
  type: 'SET_CONFIRM_MODAL_VISIBLE',
  confirmModalVisible
})

export const setCtaModalVisible = ctaModalVisible => ({
  type: 'SET_CTA_MODAL_VISIBLE',
  ctaModalVisible
})

export const setGlobalMessage = globalMessage => ({
  type: 'SET_GLOBAL_MESSAGE',
  globalMessage
})
