import React from 'react'
import { Skeleton, Card, Row, Col, Space } from 'antd'
import UserProfileForm from './profileForm'
import { Link } from 'react-router-dom'
import { subName } from '../../utils/helpers'

class StyledUserProfile extends React.Component {
  render () {
    const { formSaving, updateUserProfile, isMobile, userData } = this.props
    let validToDate

    if(userData.sub_type !== 'free') {
      const d0 = new Date(userData.valid_to_date)
      d0.setTime(d0.getTime() - 345600000) // substract 4 days (added extra for charging sub)

      validToDate = d0.toISOString().replace('T', ' ').substring(0, 16)
    }

    return (
      <>
        <div className='profile-skeleton'>
          <Skeleton active loading={!userData} />
        </div>
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={{span: 24, offset: 0}} lg={{span: 12, offset: 6}}>
            <Card
              title='Edytuj dane'
            >
              <UserProfileForm
                onHandleSubmit={updateUserProfile}
                formSaving={formSaving}
                isMobile={isMobile}
                userData={userData}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={{span: 24, offset: 0}} lg={{span: 12, offset: 6}}>
            <Card
              title='Twój pakiet'
            >
              {userData && <>
                <p>
                  {subName(userData.sub_type)}
                  {userData.trial && ' (okres testowy)'}
                  {userData.stripe_subscription_id && userData.sub_price_gross && ' (' + (userData.sub_price_gross / 100) + ' zł miesięcznie)'}
                  {!userData.stripe_subscription_id && userData.sub_type !== 'free' && ' (subskrypcja anulowana)'}
                </p>
                <p>Konto ważne do: {userData.sub_type === 'free' ? 'bezterminowo' : validToDate}</p>
              </>}
              <Space direction='vertical'>
                <Link to='/historia-platnosci'>Lista płatności</Link>
                {/*userData?.sub_type === 'free' || userData?.trial) && <Link to='/pakiety'>Wybierz pakiet</Link>*/}
                {userData?.sub_type !== 'free' && !userData?.trial && userData.stripe_subscription_id && <Link to='/anuluj-subskrypcje'>Anuluj subskrypcję</Link>}
                {/*userData.sub_type !== 'free' && !userData.trial && <Link to='/zmien-pakiet'>Zmień pakiet</Link>*/}
              </Space>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={{span: 24, offset: 0}} lg={{span: 12, offset: 6}}>
            <Card
              title='Twoje dane i operacje na koncie'
            >
              <Space direction='vertical'>
                <Link to='/data-info'>Dowiedz się jak uzyskać informacje o danych jakie gromadzimy i jak je usunąć</Link>
                <Link to='/delete-account'>Usuń konto</Link>
              </Space>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default StyledUserProfile
