/* eslint-disable camelcase */
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import './assets/scss/motion_modules/motion.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ConfigProvider } from 'antd'
import pl_PL from 'antd/es/locale/pl_PL'
// import settings from './config'

import { Provider } from 'react-redux'
import store from './store/'

// import ReactGA from 'react-ga4'
// import ReactPixel from 'react-facebook-pixel'

const version = 'v0.0.1'

// if (process.env.NODE_ENV !== 'development') {
//   ReactPixel.init('1473460483606227')
//   ReactPixel.pageView()
// }

// ReactGA.initialize('G-EPGKEBLRPT', { testMode: process.env.NODE_ENV === 'development' || settings.testMode })
// ReactGA.send('pageview')
//
// if (window.matchMedia('(display-mode: standalone)').matches) {
//   ReactGA.event({ category: 'App', action: 'Installed version of app opened' })
// }
//
// ReactGA.event({ category: 'App', action: 'App Version: ' + version })

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={pl_PL}>
      <Provider store={store}>
        <App version={version} />
      </Provider>
    </ConfigProvider>

  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
