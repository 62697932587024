import React from 'react'
import TweenOne from 'rc-tween-one'
import { OverPack } from 'rc-scroll-anim'
import QueueAnim from 'rc-queue-anim'
import { Row, Col } from 'antd'
import { getChildrenToRender, isImg } from '../../utils/helpers'
// import { HeartOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import settings from '../../config'

export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      // {
      //   name: 'block0',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: {
      //     className: 'logo',
      //     isImg: true,
      //     // children: '/images/logo.png'
      //   },
      //   childWrapper: {
      //     className: 'slogan',
      //     children: [
      //       {
      //         name: 'content0',
      //         className: 'content0',
      //         // children: 'Wszystko dla Cukierników!'
      //       }
      //     ]
      //   }
      // },
      {
        name: 'block1',
        xs: 24,
        sm: 12,
        md: 6,
        className: 'block',
        title: { children: 'Informacje' },
        childWrapper: {
          children: [
            { name: 'link0', className: 'link', children: <Link to='/regulations'>Regulamin</Link> },
            { name: 'link1', className: 'link', children: <Link to='/privacy-policy'>Polityka prywatności</Link> },
            { name: 'link2', className: 'link', children: <Link to='/cookie-policy'>Polityka cookie</Link> },
            { name: 'link3', className: 'link', children: <Link to='/contact'>Kontakt</Link> }
          ]
        }
      },
      {
        name: 'block2',
        xs: 24,
        sm: 12,
        md: 6,
        className: 'block',
        title: { children: 'Twoje konto' },
        childWrapper: {
          children: [
            { name: 'link0', className: 'link', children: <Link to='/login'>Zaloguj się</Link> },
            { name: 'link1', className: 'link', children: <Link to='/register'>Zarejestruj się</Link> }
          ]
        }
      },
      {
        name: 'block2',
        xs: 24,
        sm: 12,
        md: 6,
        className: 'block',
        title: { children: '' },
        childWrapper: {
          children: [
            { name: 'link0', className: 'link', children: <a href='https://play.google.com/store/apps/details?id=pl.strefacukiernika.twa' target='_blank' rel='noreferrer'><img className='get-it-on' alt='Pobierz aplikację w Google Play' src='/images/google.jpg' /></a> },
            { name: 'link1', className: 'link', children: <a href='https://apps.apple.com/us/app/strefa-cukiernika/id6463805746' target='_blank' rel='noreferrer'><img className='get-it-on' alt='Pobierz aplikację w App Store' src='/images/apple.jpg' /></a> }
          ]
        }
      }
    ]
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright'
  }
}

class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1'
  }

  state = {
    dataSource: FooterDataSource
  }

  componentDidMount () {
    const dataSource = this.state.dataSource

    if (this.props.location?.pathname === '/') {
      dataSource.wrapper.className = dataSource.wrapper.className + ' on-index'

      this.setState({ dataSource })
    }
  }

  componentDidUpdate (prevProps) {
    const dataSource = this.state.dataSource

    if (prevProps.authenticated !== this.props.authenticated) {
      if (this.props.authenticated) {
        dataSource.block.children.find(x => x.name === 'block2').childWrapper.children = [{ name: 'link0', className: 'link', children: <Link to='/profile'>Edytuj profil</Link> }]
      }
      else {
        dataSource.block.children.find(x => x.name === 'block2').childWrapper.children = [
          { name: 'link0', className: 'link', children: <Link to='/login'>Zaloguj się</Link> },
          { name: 'link1', className: 'link', children: <Link to='/register'>Zarejestruj się</Link> }
        ]
      }

      this.setState({ dataSource })
    }

    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      if (this.props.location?.pathname === '/') {
        dataSource.wrapper.className = 'home-page-wrapper footer1-wrapper on-index'
      } else {
        dataSource.wrapper.className = 'home-page-wrapper footer1-wrapper'
      }

      this.setState({ dataSource })
    }
  }

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
            (title.children.match(isImg) || title.isImg ? (
              <img src={title.children} width='100%' alt='img' />
            ) : (
              title.children
            ))}
          </h2>
          <div {...childWrapper} style={{ textAlign: 'center' }}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
        </Col>
      )
    })

  render () {
    const { ...props } = this.props
    const { dataSource } = this.state
    delete props.dataSource
    delete props.isMobile

    const childrenToRender = this.getLiChildren(dataSource.block.children)

    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type='bottom'
            key='ul'
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key='copyright'
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                <span>
                  ©2024 by StrefaCukiernika.pl {settings.testMode && <>({this.props.version})</>}
                </span>
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    )
  }
}

export default withRouter(Footer)
